<template>
  <main>
    <make-banner :banner="config.pc_login_logo">
      <div class="login-box core row-end">
        <div class="login">
          <div class="change-title xxl">
            <span :class="{ active: loginType == 1 }" @click="loginType = 1"
              >账号登录</span
            >
            /
            <span :class="{ active: loginType == 2 }" @click="loginType = 2"
              >短信登录</span
            >
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item class="form-input" prop="mobile">
              <div class="form-title row xs lighter">
                <img src="@/assets/icon/icon-user1.png" alt="" />
                <span>用户名</span>
              </div>
              <el-input
                v-model.trim="ruleForm.mobile"
                placeholder="用户名/邮箱/手机号"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-input"
              v-if="loginType == 1"
              prop="password"
            >
              <div class="form-title row xs lighter">
                <img src="@/assets/icon/icon-pwd.png" alt="" />
                <span>密码</span>
              </div>
              <el-input
                v-model.trim="ruleForm.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
<!-- 新增短信验证登录 判断是否打开短信验证开关 -->
            <div
              class="row-between"
              style="margin-bottom: 22px"
              v-if="loginType == 1 && config.sms_login_setting == 1"
            >
              <el-form-item class="form-input code" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入手机验证码"
                ></el-input>
              </el-form-item>
              <div
                @click="countDown"
                class="get-code lighter row-center"
                :class="{ disabled: !this.canClick }"
              >
                {{ content }}
              </div>
            </div>

            <div
              class="row-between"
              style="margin-bottom: 22px"
              v-if="loginType == 2"
            >
              <el-form-item class="form-input code" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入手机验证码"
                ></el-input>
              </el-form-item>
              <div
                @click="countDown"
                class="get-code lighter row-center"
                :class="{ disabled: !this.canClick }"
              >
                {{ content }}
              </div>
            </div>
            <el-form-item class="forgot">
              <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox label="下次自动登录" name="type"></el-checkbox>
              </el-checkbox-group>
              <router-link to="/forgot" class="primary xs"
                >忘记密码</router-link
              >
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                style="margin-top: 20px"
                @click="submitForm('ruleForm')"
                >{{ loginRequest ? "登录中" : "登录" }}</el-button
              >
            </el-form-item>
            <router-link to="/register" class="normal register"
              >申请入驻</router-link
            >
          </el-form>
        </div>
      </div>
    </make-banner>

    
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="360px"
      center 
      custom-class="dialogVisible"
      :before-close="dialogClose"
    >
      <div class="dialogVisible_msg">
        尊敬的湘雅医院老师，湖南慧采竭诚为您服务!
      </br></br>
      (专属价格，请勿外泄)
      </div>
    </el-dialog>

  </main>
</template>
<script>
import MakeBanner from "@/components/MakeBanner";
import { vxRule } from "@/utils/validator";
import { mapMutations, mapState } from "vuex";
import { EventBus } from '@/main';
export default {
  name: "",
  props: {},
  components: {
    "make-banner": MakeBanner,
  },
  data() {
    return {
      ruleForm: {
        mobile: this.$Cache.get("loginInfo").mobile || "",
        password: this.$Cache.get("loginInfo").password || "",
        code: "",
        type: "",
      },
      loginType: 1,
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      loginRequest: false,
      rules: {
        mobile: vxRule(true, "Mobile", "blur", "请填写手机号码"),
        password: vxRule(true, null, "blur", "请填写密码"),
        code: vxRule(true, null, "change", "请填写手机验证码"),
      },
      dialogVisible: false
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  watch: {},
  methods: {
    ...mapMutations("user", ["SET_USER_INFO"]),
    submitForm(formName) {
      let that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.loginRequest) return;
          this.loginRequest = true;
          let api =
            this.loginType == 1
              ? this.$api.loginApi
              : this.$api.sendSmsLoginApi;
          let { code, data, msg } = await api(this.ruleForm);
          if (code == 1) {
            // 登录权限控制
            switch (data.qualification_status) {
              // 资质未填写
              case 0:
                this.SET_USER_INFO(data);
                this.$router.replace("/certification");
                break;
              // 资料审核中
              case 1:
                this.$message({
                  message: "资质审核中，暂时无法登录",
                  type: "warning",
                });
                break;
              // 资料审核不通过
              case 3:
                this.$message({
                  message: "资质审核不通过，请重新填写",
                  type: "warning",
                });
                this.SET_USER_INFO(data);
                this.$router.replace("/certification?type=error");
                break;
              default:
                // 自动登录选项
                this.SET_USER_INFO(data);
                if (this.ruleForm.type) {
                  let loginInfo = {
                    mobile: this.ruleForm.mobile,
                    password: this.ruleForm.password,
                  };
                  this.$Cache.set("loginInfo", loginInfo, 86400 * 7);
                } else {
                  this.$Cache.remove("loginInfo");
                }
                if(data.company_id==61){ // 2024/3/3 isea 添加欢迎提示
                  that.dialogVisible = true
                  return;
                }




                this.$message({
                  message: msg,
                  type: "success",
                  onClose: () => {
                    // console.log(data);
                    var url="/index";
                    if(data.company_id==61){//如果是湘雅公司ID 则直接跳转商品列表页  owen 20240226   页面路径 /views/index/prolist
                      url="/prolist";
                    }                   
                    this.$router.replace(url);
                  },
                });
            }
          }
          this.loginRequest = false;
        } else {
          return false;
        }
      });
    },
    // 关闭欢迎层
    dialogClose() {
      this.dialogVisible = false
      this.$router.replace("/prolist");
      // this.$api.getCategoryApi().then((res) => {
      //      EventBus.$emit('global-event-categoryList', res.data); 
      // });  
    },
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      this.$api.getSmsSendApi({
        mobile: this.ruleForm.mobile,
        key: "YZMDL",
      });
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
  },
  created() {},
  mounted() {
    let that = this;
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (e && e.keyCode == 13) {
        //回车键的键值为13
        that.submitForm("ruleForm"); //调用登录按钮的登录事件
      }
    };
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.login-box {
  padding: 60px 90px 90px 0;

  .login {
    width: 435px;
    background-color: $-color-white;
    padding: 38px 38px 0;

    .change-title {
      color: #b0b0b0;
      margin-bottom: 37px;
      cursor: pointer;

      .active {
        color: $-color-normal;
      }
    }
  }

  .form-title {
    width: 100px;
    position: relative;
    flex-shrink: 0;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 35px;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
      background: $-color-border;
    }

    img {
      width: 30px;
      height: 30px;
      margin-left: 7px;
      margin-right: 5px;
    }
  }

  .forgot {
    margin-bottom: 15px;

    /deep/ .el-form-item__content {
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }

      .el-checkbox-group {
        display: inherit;
      }
    }
  }

  .get-code {
    width: 133px;
    height: 54px;
    background: #ededed;
    flex-shrink: 0;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .form-input {
    &.code {
      margin-bottom: 0;
      width: 218px;
    }

    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
      height: 54px;
      line-height: 54px;
      border: $-solid-border;

      .el-input__inner {
        border: none;
      }
    }
  }

  /deep/ .el-button {
    background-color: $-color-primary;
    border-color: $-color-primary;
    border-radius: 0;
    width: 100%;
    height: 54px;
  }

  .register {
    margin-top: -10px;
    margin-bottom: 30px;
    display: block;
  }
}
.dialogVisible {    
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline !important;
  background-color: #00000036;
  z-index: 1000;
}
.dialogVisible_msg {    
  text-align: center;
    padding: 0px 0 30px;
}
</style>
